import React from "react";

const Footer = () => {
	return (
		<footer>
			{/* Footer content */}
		</footer>
	);
};

export default Footer;
